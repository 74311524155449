.form-check {
  .custom-control-input.filled:checked ~ .custom-control-label {
    &::after {
      background-image: none !important;
    }
  }

  // FD: TODO: Check color in previous version
  // .custom-control-input.filled ~ .custom-control-label::before {
  //   @include gradient-bg($custom-control-indicator-checked-bg);
  //   border-color: $custom-control-indicator-checked-bg;
  // }
}

.custom-control-input {
  &:checked ~ .custom-control-label::before {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
}
