.user-avatar {
  border-radius: 50%;
  border: 0;
  color: $white !important;
  width: 2.25rem;
  height: 2.25rem;
  text-align: center;
  position: relative;

  &.highlight-user {
    box-shadow: 0 0 0 1px $white, 0 0 0 4px var(--primary-color);
  }

  span {
    font-style: normal;
    vertical-align: middle;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    width: 2.25rem;
    height: 2.25rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 2.25rem;
    border-radius: 50%;
    border: 0;
  }
}

// Avatar in PeTr & Chat with document

cwd-document-assistant,
cwd-history,
petr-chat {
  .user-avatar {
    border-radius: 0 !important;
    text-align: left;
    height: 32px;
    width: 41px;

    &.initials {
      border-right: 8px solid var(--#{$prefix}white);
      font-weight: bold;

      span {
        height: 32px;
      }
    }

    img {
      border-radius: 0 !important;
      position: unset;
      top: unset;
      left: unset;
      width: 32px;
      height: 32px;
    }
  }
}

// Avatar in document list views
.list-view .user-toggle .user-avatar {
  width: 2rem;
  height: 2rem;

  span {
    width: 2rem;
    height: 2rem;
  }

  img {
    width: 2rem;
  }
}

// Avatar in personal settings
app-user-avatar-upload .user-avatar {
  width: 113px;
  height: 113px;
  font-size: 2.5rem;

  span {
    height: 113px;
    width: 113px;
  }

  img {
    width: 113px;
  }
}

.file-drop-area .user-avatar {
  border-radius: 50%;
  border: 0;
  outline: $gray-200 dashed 2px;
}

// Avatar in user menu button
user-menu .user-avatar {
  width: 30px;
  height: 30px;
  margin: 0 8px;

  span {
    width: 30px;
    height: 30px;
  }

  img {
    width: 30px;
  }
}

// Avatar in user menu dropup
user-menu .dropdown-menu .user-avatar {
  width: 20px;
  height: 20px;
  margin: 0;

  span {
    font-size: 0.625rem;
    width: 20px;
    height: 20px;
  }

  img {
    width: 20px;
  }
}
