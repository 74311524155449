html,
body,
app-root,
.app {
  height: 100%;
}

body {
  // FD: TODO: Collect unnamed colors
  background: #d0d7db;
  overflow: hidden;
  padding: 0;
}

/* Layout Grid */

app-full-page {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 0;
  grid-template-areas:
    "global-notifications global-notifications"
    "sidebar main";
}

.global-notifications {
  grid-area: global-notifications;
}

.sidebar {
  grid-area: sidebar;
  // FD: TODO: Collect un-named colors
  background-color: #fbfdfe;
}

.main {
  grid-area: main;
  // FD: TODO: Collect un-named colors
  background: linear-gradient(314deg, #f2f5f7, #f1f4f6);
  overflow: hidden;
  padding: 0 1.5rem 1.5rem 1.5rem;

  /* Content Grid */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 0;
  grid-template-areas:
    "header"
    "content-outlet";

    .header {
      grid-area: header;
      overflow: hidden;
    }

    .content-outlet {
      grid-area: content-outlet;
      overflow: hidden;
    }

    /* Pull wrapping view containers out of context: This way component containers can easily use the remaining height */
    .content-outlet > *,
    .content-outlet > * > * {
      display: contents;
    }
}
