@import './typo';

.pagination {
  margin: 0;

  .page-item .page-link {
    text-decoration: none;
    background-color: transparent;
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid var(--#{$prefix}gray-200);
  padding: 12px 0 0 0;

  .pagination {
    margin: 0;

    .pagination {
      .page-item {
        text-decoration: none;
        &:not(:last-child) {
          margin-right: 8px;
        }

        &.active .page-link {
          background-color: var(--primary-color);
          border-color: var(--primary-color);
          color: $white !important;
        }

        &.disabled {
        color: var(--#{$prefix}gray-200);

        svg path {
            fill: var(--#{$prefix}gray-200);
          }
        }

        svg path {
          fill: var(--primary-color);
        }

        .page-link {
          border: none;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: normal !important;
          border-radius: 2px;
          padding: 0;
          text-decoration: none;

          &:not(:last-child) {
            margin-right: 8px;
          }

          &:hover {
            background: var(--primary-color);
            color: var(--#{$prefix}white);

            svg path {
              fill: var(--#{$prefix}white);
            }
          }
        }
      }
    }
  }

  .pagination-counter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0;

    select {
      width: auto;
      margin-right: 8px;
      background: none;
      border: none;
      text-decoration: underline;
      cursor: pointer;
      color: var(--primary-color);
      fill: var(--primary-color);
      font-size: rem(12px);
      border-bottom: 2px solid transparent;

      &:hover {
        text-decoration: none;
        // FD: TODO: Collect un-named colors
        background-color: #f3f5f7;
        border-bottom: 2px solid #869fac;
        fill: var(--#{$prefix}gray-700);
        color: var(--#{$prefix}gray-800);
      }
    }

    span {
      font-size: rem(12px);
    }
  }
}

.list-result-counter {
  white-space: nowrap;
}
