@font-face {
  font-family: 'Barlow';
  src: url('Barlow-Regular.ttf');
  src: url('Barlow-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Light';
  src: url('Barlow-Light.ttf');
  src: url('Barlow-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Bold';
  src: url('Barlow-Bold.ttf');
  src: url('Barlow-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
