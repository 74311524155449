@import './typo';

@mixin button-before {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-radius: 100%;
  z-index: -1;
  pointer-events: none;
}

@mixin button-after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "";
  z-index: -1;
  pointer-events: none;
  border-radius: inherit;
  left: calc(50% - 15px);
  width: 30px;
  top: 0;
  height: 100%;
  transition: all 0.3s ease;
}

@mixin button {
  font-weight: bold;
  font-family: Barlow;
  font-size: 14px;
  padding: 7px 12px;
  transition: all 0.2s ease;
  border-radius: 2px;
  border: none;
  color: var(--#{$prefix}white);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;

  &:active {
    border-style: unset;
  }

  span ~ *, * ~ span {
    margin-left: 6px;
    line-height: normal;
    margin-top: -2px;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &[disabled], &:disabled, &.disabled {
    font-weight: normal;
    cursor: pointer;
    pointer-events: none;

    &::before, &::after {
      display: none;
    }
  }
}

.button-primary, .button-primary-outlined,
.button-secondary, .button-secondary-outlined {
  @include button;
}

.button-primary, .button-primary-outlined {
  font-weight: normal;
  font-size: rem(12px);
}

.button-secondary, .button-secondary-outlined {
  font-weight: bold;
}

.button-primary, .button-secondary {
  // normal style

  &::after {
    @include button-after;
  }

  &:hover {
    &::after {
      opacity: 1;
      z-index: 0;
    }
  }

  &:active::after {
    // FD: TODO: Collect un-named colors
    box-shadow: inset 0 0 20px 9px #fff3;
  }

  &[disabled], &:disabled, &.disabled {
    background: var(--#{$prefix}gray-200);
    color: var(--#{$prefix}white);
  }
}

.button-primary-outlined, .button-secondary-outlined {
  // outlined style

  &::before {
    @include button-before;
  }

  &:hover {
    box-shadow: inset 0 0 0 2px var(--#{$prefix}gray-200), rgb(0 0 0 / 8%) 0 4px 5px 0;

    &::before {
      opacity: 1;
      z-index: 0;
      animation: ripple-secondary-outlined 0.8s linear 0s 1;
    }
  }

  &:active {
    border-style: unset;
    box-shadow: inset 0 0 0 2px var(--secondary-color);
    background: white;
  }

  &[disabled], &:disabled, &.disabled {
    color: $gray-300;
    box-shadow: inset 0 0 0 2px var(--#{$prefix}gray-200);
    background: transparent;
  }
}

.button-primary {
  color: white;
  background: var(--primary-color);

  &:hover {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--primary-color), rgb(0 0 0 / 12%) 0 4px 5px 0;

    &::after {
      opacity: 1;
      left: 0;
      width: 100%;
      background: var(--primary-color);
      border-radius: inherit;
    }
  }
}

.button-primary-outlined {
  color: var(--primary-color);
  background: transparent;
  box-shadow: inset 0 0 0 2px var(--primary-color);
}

.button-secondary {
  color: var(--#{$prefix}white);
  background: var(--secondary-color);

  &:hover {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--secondary-color), rgb(0 0 0 / 12%) 0 4px 5px 0;

    &::after {
      opacity: 1;
      left: 0;
      width: 100%;
      background: var(--secondary-color);
      border-radius: inherit;
    }
  }
}

.button-secondary-outlined {
  color: var(--secondary-color);
  background: transparent;
  box-shadow: inset 0 0 0 2px var(--secondary-color);
}

a.btn, button {
  &.btn-primary.btn, &.btn-success, &.btn-warning, &.btn-info, &.btn-danger, &.btn-dark {
    &.btn-success, &.btn-warning, &.btn-info, &.btn-danger, &.btn-dark {
      &:not(.disabled) {
        background: var(--primary-color);
        border: none;
        font-family: 'Barlow', 'Calibri', sans-serif;
        padding: 7px 11px;
        white-space: nowrap;
        height: 33px;
        color: var(--#{$prefix}white);

        &:hover, &:active, &:focus, &:active, &:active:focus {
          background: var(--primary-color);
          outline: 0;
          position: relative;
          box-shadow: none;

          &::before {
            @include  button-before;
            border: 3px solid var(--primary-color);
            opacity: 0.3;
          }
        }
      }
    }
  }
}

:not(.btn-toolbar) > .btn-group > .btn:not(:first-child), :not(.btn-toolbar) > .btn-group > .btn-group:not(:first-child),
.btn-group[dropdown] button {
  margin-left: -1px;
  // FD: TODO: Collect un-named colors
  color: #0006;
  padding: 0.5rem;
  height: 33px;
  background: var(--nav-color);
  border-color: var(--nav-color);
}

.btn {
  font-weight: bold;
  letter-spacing: 0.5px;
  text-decoration: none !important;
}

.btn-meta-action {
  font-weight: normal;
  letter-spacing: normal;
  width: 150px;
  height: 41px;
  margin: 0 5px 20px 5px;
  white-space: unset !important;
  font-size: 13px !important;
}

a.btn.btn-primary {
  color: var(--#{$prefix}white);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  padding: 0.5rem 1rem;
  border-bottom: none;
  cursor: pointer;
  font-size: 0.8125rem;

  &.as-tab {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:hover {
    color: var(--#{$prefix}white);
  }
}

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $font-size-xs, $border-radius-xs);
}

.btn-group-xs > .btn { @extend .btn-xs; }

.btn-group .btn.disabled, .btn-group .btn:disabled {
  color: var(--#{$prefix}white);
  opacity: 1;
}


.btn-secondary,
.btn-success,
.btn-info,
.btn-warning {
  color: var(--#{$prefix}white);

  &:focus,
  &:active,
  &:hover {
    color: var(--#{$prefix}white) !important;
  }
}

.btn-delete {
  color: var(--#{$prefix}red);

  &:hover {
    color: var(--#{$prefix}red);
  }
}

.btn-reload {
  height: 35px !important;
}

.btn-placeholder {
  visibility: hidden;
}

button:focus {
  outline: none;
}

button:active {
  border-style: solid;
}

.btn-dark {
  color: var(--#{$prefix}white) !important;
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: lighten($primary, 20%);
  border-color: lighten($primary, 20%);
}

.btn.disabled-dark:disabled {
  color: color-contrast($background);
  opacity: 0.65;

  &:hover {
    color: var(--#{$prefix}white);
  }
}

.btn.btn-list-fixed-width {
  font-weight: normal !important;
  color: var(--#{$prefix}white);
  width: 114px;
  padding: 10px 7px 9px 12px;
  font-size: 11px;
  align-items: center;
  text-align: left;
  display: flex;

  .icon {
    min-width: 19px;
    display: inline-block;
  }
}

.plain-text.btn:disabled {
  opacity: 1;
}

.pt-label {
  padding-top: 21px;
}

.btn-group {
  .btn-reload,
  .dropdown-toggle-single {
    border-radius: $border-radius-xs !important;
    border-top-right-radius: $border-radius-xs !important;
    border-bottom-right-radius: $border-radius-xs !important;
  }
}

.btn-check:checked + .btn {
  background-color: #95c9d4;
}

@keyframes ripple-secondary {
  from {
    // FD: TODO: Collect un-named colors
    background: linear-gradient(180deg, #fff3, var(--secondary-color));
  }

  to {
    background: linear-gradient(180deg, var(--secondary-color), var(--secondary-color));
  }
}

@keyframes ripple-secondary-outlined {
  from {
    padding-bottom: 0;
    width: 0;
    background: radial-gradient(transparent, var(--#{$prefix}gray-100));
  }

  to {
    padding-bottom: 400%;
    width: 400%;
    background: radial-gradient(transparent, transparent);
  }
}
