.input-group-xs > .form-control:not(textarea),
.input-group-xs > .form-select {
  height: $input-height-xs;
}

.input-group-xs > .form-control,
.input-group-xs > .form-select,
.input-group-xs > .input-group-text,
.input-group-xs > .input-group-prepend > .btn,
.input-group-xs > .btn {
  padding: $input-padding-y-xs $input-padding-x-xs;
  font-size: $input-font-size-xs;
  line-height: $input-line-height-xs;
  @include border-radius($input-border-radius-xs);
}

.input-group-xs > .form-select {
  padding-right: $form-select-padding-x + $form-select-indicator-padding;
}

// FD: TODO: .input-group-append and .input-group-prepend has been dropped. Refactor!
// .input-group > .input-group-prepend > .btn,
// .input-group > .input-group-prepend > .input-group-text,
// .input-group > .input-group-append:not(:last-child) > .btn,
// .input-group > .input-group-append:not(:last-child) > .input-group-text,
// .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
// .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
//   @include border-right-radius(0);
// }

// .input-group > .input-group-append > .btn,
// .input-group > .input-group-append > .input-group-text,
// .input-group > .input-group-prepend:not(:first-child) > .btn,
// .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
// .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
// .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
//   @include border-left-radius(0);
// }

.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group > app-input {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
