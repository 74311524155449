@mixin toastr-variant($state, $color) {
  .toast-#{$state} {
    transition: background-color 200ms linear;

    &:hover {
      background-color: shift-color($color, -9 * $theme-color-interval);
      transition: background-color 200ms linear;
    }

    .toast-close-button:hover {
      color: shift-color($color, 6);
    }
  }
}

#toast-container {
  bottom: 2rem;

  & > div {
    opacity: 1;
    // FD: TODO: Collect un-named colors
    box-shadow: 0 0 20px 0 #0006;
  }
}

.toast-container {
  .ngx-toastr {
    background-size: 18px;
    padding: .5rem 1.25rem .5rem 50px;
    right: -0.1rem;
  }

  .ngx-toastr,
  .toast-title,
  .toast-message {
    font-size: 0.875rem;
  }

  .toast-close-button {
    background: transparent;
    border: none;
    padding: 0;
  }

  @include toastr-variant('info', $info);
  @include toastr-variant('success', $success);
  @include toastr-variant('warning', $warning);
  @include toastr-variant('error', $danger);
}
