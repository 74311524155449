
@import './prefix';

// Grid
// $grid-gutter-width: 20px !default;

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
$white:    #fff;
$gray-100: #f0f3f5;
$gray-200: #c2cfd6;
$gray-300: #a4b7c1;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #536c79;
$gray-700: #3e515b;
$gray-800: #29363d;
$gray-900: #151b1e;
$black:    #000;

$blue:    #4db6ce;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #f86c6b;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #76c190;
$teal:    #20c997;
$cyan:    #63c2de;

// Colors
// $blue:                                      #4db6ce;
// $indigo:                                    #6610f2;
// $purple:                                    #6f42c1;
// $pink:                                      #e83e8c;
// $red:                                       #f86c6b;
// $orange:                                    #f8cb00;
// $yellow:                                    #ffc107;
// $green:                                     #76c190;
// $teal:                                      #20c997;
// $cyan:                                      #63c2de;
// $light:                                     #dbdcdc;
// $text-color:                                #29363d;
$text-color:                                darken($blue, 10%);
$catch-beta:                                #ff7a00;
$background:                                #f9f9f9;

// scss-docs-start theme-color-variables
$primary:       $blue;
$secondary:     $gray-300;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         #dbdcdc;
$dark:          $blue;
$gray-dark:     $gray-800;
// scss-docs-end theme-color-variables

// Create your own map
// $custom-colors: (
//   "custom-color": #900
// );

// Merge the maps
// $theme-colors: map-merge($theme-colors, $custom-colors);

// $colors: (
//   'blue':                                     $blue,
//   'indigo':                                   $indigo,
//   'purple':                                   $purple,
//   'pink':                                     $pink,
//   'red':                                      $red,
//   'orange':                                   $orange,
//   'yellow':                                   $yellow,
//   'green':                                    $green,
//   'teal':                                     $teal,
//   'cyan':                                     $cyan,
//   'white':                                    $white,
//   'gray':                                     $gray-600,
//   'gray-dark':                                $gray-800,
//   'text-color':                               $text-color,
//   'catch-beta':                               $catch-beta,
//   'background':                               $background
// );

// $theme-colors: (
//   'primary':                                  $blue,
//   'secondary':                                $gray-300,
//   'success':                                  $green,
//   'info':                                     $cyan,
//   'warning':                                  $yellow,
//   'danger':                                   $red,
//   // 'light':                                    $gray-100,
//   'light':                                    $light,
//   // 'dark':                                     $gray-800
//   'dark':                                     $blue,
//   'gray-dark':                                $gray-800
// );

// change threshold for contrast calulation, $blue
// should still have white fonts. default: 150.
// $min-contrast-ratio:  155;

// Fonts
$font-family-sans-serif: 'Barlow', 'Calibri', sans-serif;
$font-family-monospace:  SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base:       $font-family-sans-serif;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  1px: (1px)
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  33: 33.333%,
  50: 50%,
  75: 75%,
  100: 100%
) !default;

// Paragraphs
//
// Style p element.
$paragraph-margin-bottom:   1rem !default;

// z-index hierarchy
$zindex-layout:              980;
$zindex-dropdown:           1000;
$zindex-sticky:             1020;
$zindex-fixed:              1030;
$zindex-navigation:         1035;
$zindex-modal-backdrop:     1040;
$zindex-modal:              1050;
$zindex-popover:            1060;
$zindex-tooltip:            1070;
$zindex-pace:               1080;
$zindex-preview:            1090;
$zindex-shortcut-help: 999999999;


// Components
//
// Define common padding and border radius sizes and more.
$line-height-base:            1.5 !default;

$line-height-lg:              1.5;
$line-height-sm:              1.5;
$line-height-xs:              1.2;

$font-size-base:              1rem;
$font-size-xs:                ($font-size-base * 0.8125);

$border-width:                1px;
$border-color:                $gray-200;

$border-radius:               .15rem;
$border-radius-lg:            .3rem;
$border-radius-sm:            .2rem;
$border-radius-xs:            .15rem;

$component-active-color:      $white;
$component-active-bg:         $primary;

$caret-width:                 .3em;

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;


$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
// $box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow:                  0 0 9px 0 rgba(200,200,200,1) !default;
$box-shadow-hover:            0 0 18px 0 rgba($black, .17) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;


// Body
$body-color:                                $blue;
// $body-bg:                                   $gray-700;
// $body-color:                $gray-900 !default;
$body-bg:                   $white !default;

$body-color:                                $gray-700;
$font-size-base:                            1rem;
$font-size-content:                         0.8125rem;
$font-size-header:                          1rem;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
// $input-btn-padding-y-xs:      .19rem;
// $input-btn-padding-x-xs:      .5rem;
// The rem values bugged the cell size calculation
// $input-btn-padding-y-xs:      4px;
// $input-btn-padding-x-xs:      9px;
// $input-btn-padding-y-xs:      .125rem;
// $input-btn-padding-x-xs:      .25rem;
$input-btn-padding-y-xs:      .25rem !default;
$input-btn-padding-x-xs:      .5rem !default;

$input-btn-line-height-xs:    $line-height-xs;

// Modal
$modal-sm: 500px;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y-xs:            $input-btn-padding-y-xs;
$btn-padding-x-xs:            $input-btn-padding-x-xs;
$btn-line-height-xs:          $input-btn-line-height-xs;
$btn-border-radius-xs:        $border-radius-xs;


// Forms

$input-bg:                              lighten($gray-100, 1%);
$input-disabled-bg:                     $gray-200;

$input-color:                           $gray-700;
$input-border-color:                    $gray-400;
$input-border-width:                    2px;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075);

$input-btn-focus-color:                 $component-active-bg;


$input-border-radius:                   0;
$input-border-radius-lg:                0;
$input-border-radius-sm:                0;

//$input-height-border:                   $input-border-width * 2;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-text-margin-top:                  .25rem;

$form-check-input-gutter:               1.25rem;
$form-check-input-margin-y:             .3rem;
$form-check-input-margin-x:             .25rem;

$form-check-inline-margin-x:            .75rem;
$form-check-inline-input-margin-x:      .3125rem;

$form-group-margin-bottom:              1rem;

$input-group-addon-color:               $input-color;
$input-group-addon-bg:                  $gray-200;
$input-group-addon-border-color:        $input-border-color;

$custom-control-gutter:                 1.5rem;
$custom-control-spacer-x:               1rem;

$custom-control-indicator-size:         1rem;
$custom-control-indicator-bg:           $gray-300;
$custom-control-indicator-bg-size:      50% 50%;

$custom-control-indicator-disabled-bg:  $gray-200;
$custom-control-indicator-border-width: $input-border-width !default;
$custom-control-indicator-border-color: $gray-500 !default;
$custom-control-label-disabled-color:   $gray-600;


$form-select-padding-y:           .375rem !default;
$form-select-padding-x:           .75rem !default;
$form-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
$form-select-color:               $input-color !default;
$form-select-disabled-color:      $gray-600 !default;
$form-select-bg:                  $light !default;
$form-select-disabled-bg:         $gray-200 !default;
$form-select-bg-size:             8px 10px !default; // In pixels because image dimensions
$form-select-indicator-color:     $gray-800 !default;
$form-select-border-color:        $light !default;
$form-select-border-radius:       $border-radius !default;
$form-select-box-shadow:          inset 0 1px 2px rgba($black, .075) !default;

$form-select-focus-border-color:  $light !default;
$form-select-focus-box-shadow:    none;
$form-select-font-size-xs:        0.8125rem;

$input-height-border:                   $input-border-width * 2 !default;

$font-size-xs:                          ($font-size-base * 0.6875) !default;
// $input-btn-padding-y-xs:             .35rem !default;
// $input-btn-padding-x-xs:             .6rem !default;
$input-btn-font-size-xs:                $font-size-xs !default;
$input-btn-line-height-xs:              $line-height-xs !default;
$input-font-size-xs:                    $input-btn-font-size-xs !default;

$input-line-height-xs:                  $input-btn-line-height-xs !default;
$input-border-radius-xs:                $border-radius-xs !default;

$input-padding-y-xs:                    $input-btn-padding-y-xs !default;
$input-padding-x-xs:                    $input-btn-padding-x-xs !default;

$input-height-inner-xs:                 ($input-btn-font-size-xs * $input-btn-line-height-xs) + ($input-btn-padding-y-xs * 2) !default;
$input-height-xs:                       calc(#{$input-height-inner-xs} + #{$input-height-border}) !default;

$form-check-input-width:                1rem;
$form-check-padding-start:              $form-check-input-width + .5rem;

// $form-switch-color:           var(--primary-color);
// $form-switch-focus-color:     var(--primary-color);
// $form-switch-bg-image:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
// $form-switch-focus-bg-image:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");
$input-focus-border-color:              tint-color($primary, 50%);
$form-check-input-focus-border:         $input-focus-border-color !default;
$form-switch-focus-color:         $input-focus-border-color !default;

// Badges

$badge-font-size:                   75% !default;
$badge-font-weight:                 600;
$badge-padding-y:                   .25em !default;
$badge-padding-x:                   .4em !default;
$badge-border-radius:               $border-radius !default;


// Tag

$tag-font-size:                   75%;
$tag-font-weight:                 600;
$tag-padding-y:                   .35rem;
$tag-padding-x:                   .4rem;
$tag-border-radius:               $border-radius;

$pagination-font-size:            ($font-size-base * 0.8125);
