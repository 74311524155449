.filter-sorting {
  &, select {
    height: 35px !important;
  }

  .form-select {
    background: none;
    background-color: var(--#{$prefix}light);
    padding-right: 0.75rem;
  }
}
