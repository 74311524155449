// Generates the card color variants
@mixin bg-variant($parent, $color) {
  #{$parent} {
    @include border-radius($card-border-radius-inner $card-border-radius-inner $card-border-radius-inner $card-border-radius-inner);
    color: #fff !important;
    background-color: $color !important;
  }
  a#{$parent} {
    &:hover,
    &:focus {
      background-color: darken($color, 10%);
    }
  }
}

// Needed in order to remove the box-shadow
@mixin custom-form-validation-state($state, $color) {
  .form-control,
  .form-select {
    .was-validated &:#{$state},
    &.is-#{$state} {
      border-color: $color;

      &:focus {
        border-color: $color;
        box-shadow: none;
      }
    }
  }
}

// Keeps top bar interactive while displaying onboarding modals
@mixin onboarding-spacing {
  ::ng-deep {
    modal-container.modal, bs-modal-backdrop.modal-backdrop {
      top: 0;
    }
    .dropdown-menu {
      z-index: 9999;
    }
    bs-dropdown-container {
      z-index: 9999;
    }
  }
}

// Parashift styling for ngx-bootstrap-multiselect
@mixin ss-multiselect {
  ngx-bootstrap-multiselect {
    display: block;
    ::ng-deep {
      .dropdown-inline {
        display: block;

        .search-container {
          padding: 0 !important;

          .input-group-prepend,
          .input-group-prepend .input-group-text {
            display: none;
          }

          .input-group-append > .btn {
            background-color: $input-bg !important;
            border-radius: 0 !important;
            border-color: $input-bg;
            border-bottom: $input-border-width solid $input-border-color;
            border-bottom-color: $input-border-color;
            color: $input-color;
            height: 100%;
            padding: 0 3px !important;

            &:focus {
              box-shadow: none !important;
            }

            i {
              display: inline-block;
              width: 15px;
              min-width: 15px;
              height: 15px;
              background-image: url('data:image/svg+xml,<svg role="img" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="transform-origin: 0.375em 0.5em;"><g transform="translate(192 256)"><g transform="translate(0, 0) scale(0.9375, 0.9375) rotate(0 0 0)"><path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" transform="translate(-192 -256)"></path></g></g></svg>');
              background-repeat: no-repeat;
            }
          }

          .form-control:focus + .input-group-append > .btn {
            border-bottom-color: var(--primary-color);
          }
        }

        &, button.dropdown-toggle {
          width: 100%;
        }

        button.dropdown-toggle {
          position: relative;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;

          .caret {
            position: absolute;
            right: 5px;
            top: 50%;
          }
        }

        button.dropdown-toggle::after {
          display: none;
        }

        .dropdown-menu {
          padding-top: 0;
          min-width: 260px;
        }

        .dropdown-item > span {
          position: relative;
          padding-left: 1.5rem;
          display: block;
        }

        .dropdown-item.dropdown-header {
          margin-top: 0.5rem;
        }

        .dropdown-item.dropdown-header > span {
          padding: 0.25rem 1rem;
          padding-left: 0;
        }

        .dropdown-item {
          text-decoration: none;
          padding: 0.25rem 1rem;
          white-space: normal;

          &:hover,
          &:focus,
          &.active,
          &:active {
            background-color: var(--#{$prefix}white);
          }

          span {
            input {
              position: absolute;
              z-index: -1;
              opacity: 0;
            }

            span {
              margin-bottom: 0;
              vertical-align: top;
              padding-left: 0;
              padding-top: .3rem;
              position: relative;

              &::before {
                position: absolute;
                top: 0.3rem;
                left: -1.5rem;
                display: block;
                width: $custom-control-indicator-size;
                height: $custom-control-indicator-size;
                pointer-events: none;
                content: "";
                background-color: $custom-control-indicator-bg;
                border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
                border-radius: .15rem;
              }

              &::after {
                position: absolute;
                top: 0.3rem;
                left: -1.5rem;
                display: block;
                width: $custom-control-indicator-size;
                height: $custom-control-indicator-size;
                content: "";
                background: no-repeat 50% / #{$custom-control-indicator-bg-size};
              }
            }

            input:checked~span::before {
              color: var(--#{$prefix}white);
              // FD: TODO: Collect un-named colors
              border-color: #4db6ce;
              background-color: #4db6ce;
            }

            input:checked~span::after {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
            }
          }
        }
      }
    }
  }
}

@mixin default-scrollbar($size: 7px, $scrollbar-color: transparent) {
  &::-webkit-scrollbar {
    position: relative;
    z-index: 1;
    width: $size;
    height: $size;
    background-color: $scrollbar-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(190, 194, 196);
  }
}
