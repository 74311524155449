.caret {
  display: inline-block;
  width: 0px;
  height: 0px;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  transition: all linear 0.25s;
}

.open a[dropdowntoggle] .caret {
  transform: rotate(180deg);
}

.dropdown-menu {
  box-shadow: 0px 0px 3px var(--#{$prefix}gray-200);
  font-size: 0.75rem;
}

.dropdown-item {
  font-size: 0.75rem;

  &:hover {
    text-decoration: none;
  }
}

typeahead-container.dropdown-menu {
  z-index: 1050 !important;
}

.datetimepicker-component {
  bs-datepicker-inline-container {
    margin-bottom: 10px;
  }

  .bs-datepicker-container {
    padding: 0 !important;
  }

  .bs-datepicker {
    box-shadow: none !important;
  }
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--primary-color);
}

ul.header-dropdown-menu {
  z-index: $zindex-dropdown;
  border: 2px solid var(--secondary-color);
  transform: translateX(21px) !important;
  padding: 0.5rem 0 !important;
  margin-top: 6px;
  right: -22px !important;

  li a {
    &.disabled {
      color: var(--#{$prefix}gray-200);
    }

    &.delete {
      color: var(--#{$prefix}red);

      &:hover {
        background: var(--#{$prefix}red);
        color: var(--#{$prefix}white);
      }
    }
  }

  li.dropdown-divider:first-child {
    display: none;
  }
}
