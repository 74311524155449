html, body {
  -webkit-font-smoothing: antialiased;
}

app-root {
  font-size: $font-size-content;
}

body.typeahead-container-small {
  .dropdown-menu {
    min-width: unset !important;
  }
}
