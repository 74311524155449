.filter-column {
  background: var(--#{$prefix}white);
  height: 100%;
  overflow: hidden;
  width: 200px;
}

.tags-dropdown {
  max-height: 277px;
  overflow-y: auto;
}
