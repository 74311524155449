.theme-dark-blue .bs-datepicker-head {
  background-color: var(--primary-color) !important;
}

.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-dark-blue .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: var(--primary-color) !important;
}
