.document-thumbnail {
  & > div {
    .parent {
      display: flex;
      // FD: TODO: Collect un-named colors
      border: thin solid #c4c4c4;
      border-radius: 2px;
      width: fit-content;

      .img-wrapper {
        height: 35px;
        overflow: hidden;

        img {
          width: 55px;
          cursor: zoom-in;
          border: none;

          &.missing-preview {
            margin-top: -17px;
          }
        }
      }

      .doc-info {
        background: rgba(var(--#{$prefix}gray-100), 0.59);
        color: var(--#{$prefix}gray-800);
        pointer-events: none;
        padding: 2px 3px;
        font-size: 0.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 35px;

        fa-icon {
          margin-top: 2px;
          margin-left: 2px;
          color: var(--#{$prefix}gray-400);
        }

        .count {
          font-size: 0.6875rem
        }
      }
    }
  }
}

document-thumbnail-preview {
  display: inline-block;
  left: 100px;
  overflow: hidden;
  padding: 9px;
  position: fixed;
  top: 50px;
  z-index: $zindex-preview;

  img {
    border: 1px solid var(--#{$prefix}gray-200);
    box-shadow: 0 0 9px 0 var(--#{$prefix}gray-200);
    height: auto;
    width: 100%;
    max-width: 100%;

    &.missing-preview {
      max-width: 500px;
    }
  }
}
