@mixin checkbox-border($color) {
  box-shadow: 0 0 0 1px var(--#{$prefix}white), 0 0 0 0.2rem $color;
}

//
// Check/radio
//

.form-check-input {
  background-color: var(--#{$prefix}gray-300);
  // background-image: var(--#{$prefix}form-check-bg-image);
  border: 2px solid var(--#{$prefix}gray-500);

  // &[type="checkbox"] {
  //   @include border-radius($form-check-input-border-radius);
  // }

  // &[type="radio"] {
  //   border-radius: $form-check-radio-border-radius;
  // }

  &:focus {
    border-color: var(--#{$prefix}gray-500);
    @include checkbox-border(var(--primary-color));

    &.has-warning {
      border-color: var(--#{$prefix}warning);
      @include checkbox-border(var(--#{$prefix}warning));
    }

    &.has-error {
      border-color: var(--#{$prefix}danger);
      @include checkbox-border(var(--#{$prefix}danger));
    }
  }

  &:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);

    &.has-warning {
      background-color: var(--#{$prefix}warning);
      border-color: var(--#{$prefix}warning);
    }

    &.has-error {
      background-color: var(--#{$prefix}danger);
      border-color: var(--#{$prefix}danger);
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: var(--primary-color);
    border-color: var(--primary-color);

    &.has-warning {
      background-color: var(--#{$prefix}warning);
      border-color: var(--#{$prefix}warning);
    }

    &.has-error {
      background-color: var(--#{$prefix}danger);
      border-color: var(--#{$prefix}danger);
    }
  }

  &[type="checkbox"].filled {
    background-color: var(--primary-color);
    border-color: var(--primary-color);;

    &.has-warning {
      background-color: var(--#{$prefix}warning);
      border-color: var(--#{$prefix}warning);
    }

    &.has-error {
      background-color: var(--#{$prefix}danger);
      border-color: var(--#{$prefix}danger);
    }
  }
}

//
// Switch
//

.form-switch {
  .form-check-input {
    // --#{$prefix}form-switch-bg: #{escape-svg($form-switch-bg-image)};

    background-color: var(--#{$prefix}white);
    background-position: left center;
    border: 2px solid var(--primary-color);
    @include border-radius($form-switch-border-radius);
    // @include transition($form-switch-transition);

    &:focus {
      --#{$prefix}form-switch-bg: #{escape-svg($form-switch-bg-image)};
    }

    &:checked {
      // background-position: $form-switch-checked-bg-position;
      background-color: var(--primary-color);

      // @if $enable-gradients {
      //   --#{$prefix}form-switch-bg: #{escape-svg($form-switch-checked-bg-image)}, var(--#{$prefix}gradient);
      // } @else {
      //   --#{$prefix}form-switch-bg: #{escape-svg($form-switch-checked-bg-image)};
      // }
    }
  }
  // &.form-check-reverse {
  //   padding-right: $form-switch-padding-start;
  //   padding-left: 0;

  //   .form-check-input {
  //     margin-right: $form-switch-padding-start * -1;
  //     margin-left: 0;
  //   }
  // }
}

// .form-check-inline {
//   display: inline-block;
//   margin-right: $form-check-inline-margin-end;
// }

// .btn-check {
//   position: absolute;
//   clip: rect(0, 0, 0, 0);
//   pointer-events: none;

//   &[disabled],
//   &:disabled {
//     + .btn {
//       pointer-events: none;
//       filter: none;
//       opacity: $form-check-btn-check-disabled-opacity;
//     }
//   }
// }

@if $enable-dark-mode {
  @include color-mode(dark) {
    .form-switch .form-check-input:not(:checked):not(:focus) {
      --#{$prefix}form-switch-bg: #{escape-svg($form-switch-bg-image-dark)};
    }
  }
}
