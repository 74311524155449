body.simple-page {
  background-color: var(--#{$prefix}background);
  overflow-x: hidden;
  padding: 0 20px;
}

app-simple-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
