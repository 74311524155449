@import './bootstrap-variables';

$primary-color: $blue;
$secondary-color: $gray-700;


// Body
$body-color:                                $blue;
$body-bg:                                   $white !default;

$body-color:                                $gray-700;
$font-size-base:                            1rem;
$font-size-content:                         0.8125rem;
$font-size-header:                          1rem;

// Links
$link-color:                                $primary;
$link-decoration:                           none;
$link-hover-color:                          darken($link-color, 15%);
$link-hover-decoration:                     underline;

// Common
$border-color:                              $gray-200 !default;
$layout-transition-speed:                   .5s !default;

// Header
$header-height:                             58px;

// Sidebar
$sidebar-width:                             220px;
$sidebar-minimized-width:                   70px;
$sidebar-background-color:                  $gray-700;

// Toolbar
$toolbar-width:                             220px;
$toolbar-background-color:                  $gray-800;
$toolbar-padding:                           18px 16px;

// Navigation
$navigation-font-size:                      0.8325rem;
// $navigation-font-size:                      0.8125rem;
$navigation-font-weight:                    600;
$navigation-item-border-active-width:       2px;
$navigation-item-border-active:             $navigation-item-border-active-width solid $white;
$navigation-item-background-active:         darken($sidebar-background-color, 7);
$navigation-item-background-hover:          darken($sidebar-background-color, 7);

// Breadcrumb
$breadcrumb-padding-y:                      0;
$breadcrumb-padding-x:                      16px;
$border-radius:                             2px;
$spacing-sm:                                8px;
$spacing-md:                                20px;
$breadcrumbs-height:                        53px;
$breadcrumbs-spacing-bottom:                20px;
$tabs-height:                               38px;
$table-full-header-height:                  95px;
$table-pagination-height:                   50px;
$page-paddings:                             2 * 24px;
$page-content-height:                       calc(100vh - #{$breadcrumbs-height} - #{$page-paddings} - #{$breadcrumbs-spacing-bottom});
$info-box-height:                           236px;

// Infobox
$infobox-height:                            257px;

// Focus variables
$focus-ring-width:                          0;
$focus-ring-opacity:                        0;
$focus-ring-color:                          transparent;
$focus-ring-blur:                           0;
$focus-ring-box-shadow:                     none;
