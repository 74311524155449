@use 'sass:math';

.card.card.form-card {
  .card-header {
    position: sticky;
    top: -1px;
    // FD: TODO: Collect un-named colors
    border-top: thin solid #c4c4c4;
    margin-top: -1px;
    z-index: $zindex-sticky;
    background: var(--#{$prefix}white);
    padding-bottom: 12px;

    h3 {
      margin: 0;
    }

    .card-header + .card-body {
      padding-top: 0;
    }
  }
}

.card {
  margin-bottom: $spacer;

  &.card-selectable {
    background: $input-bg;
    border-bottom: $input-border-width solid $input-border-color;
    box-shadow: none;
    cursor: pointer;

    .card-body {
      padding: 0.5rem;
    }

    &:hover {
      background-color: darken($input-bg, 3);
      border-bottom-color: $input-btn-focus-color;
    }
  }

  // FD: TODO: Collect un-named colors
  border: thin solid #c4c4c4;

  &.card-hover:hover {
    box-shadow: $box-shadow-hover;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 0.875rem;
  }

  .card-header {
    border-bottom: none;
    font-weight: 600;
    background-color: var(--#{$prefix}white);
    font-size: $font-size-header;
    padding-top: 1rem;
    padding-bottom: 0;
    line-height: 1.1;
    min-height: 51px;

    a {
      color: var(--#{$prefix}white);
      color: var(--#{$prefix}text-color);


      &:hover {
        color: darken($white, 10);
        color: darken($text-color, 10);
      }
    }

    h3 {
      line-height: 1.5;
    }

    &.restrict-height {
      height: 400px;
      overflow-y: scroll;
      padding: 0;

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .card-header.restrict-height + .card-body {
    padding: 0;
  }

  .card-body {
    a {
      color: var(--#{$prefix}body-color);
      text-decoration: underline;
      font-weight: 600;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &.form-card {
    .card-body {
      padding-bottom: .25rem;
    }
  }

  &.dark-header {
    .card-header {
      background-color: var(--#{$prefix}gray-700);
      color: $white;
      padding: 0.75rem 1.25rem;
    }
  }

  &.dashboard {

    &.highlight {
      .card-header {
        background: var(--#{$prefix}green);
      }
    }

    .card-header {
      background-color: var(--#{$prefix}gray-700);
      color: $white;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      padding-left: 0.8rem;
      padding-right: 15px;
    }

    .card-body {
      padding: 0;

      &.no-table {
        padding: 0 0.8rem;
      }

      &--stats {
        padding: 5px 30px;
        overflow: hidden;
      }

      &__number {
        text-align: center;
        font-size: 42px;
        margin-top: 8px;
        margin-bottom: -12px;
      }

      &__subtitle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 50px;
      }

      &__action {
        border-top: 1px solid var(--#{$prefix}gray-200);
        overflow: hidden;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 10px;
        margin-top: 6px;
      }
    }

    .badge {
      margin-top: -2px;
      vertical-align: middle;
    }
  }

  @each $color, $value in $theme-colors {
    &.bg-header-#{$color} {
      background-color: var(--#{$prefix}white) !important;

      .card-header {
        background-color: $value;
        color: var(--#{$prefix}white);
        a {
          color: var(--#{$prefix}white);

          &:hover {
            color: darken($white, 10);
          }
        }
      }
    }
  }

  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      background-color: $value !important;
      color: var(--#{$prefix}white);

      .card-header {
        background-color: darken($value, 5);
        color: var(--#{$prefix}white);
      }

      .card-footer {
        background-color: $value !important;
        border-top: 1px solid darken($value, 5);
        color: var(--#{$prefix}white);
      }
    }
  }

  .card-footer {
    background-color: var(--#{$prefix}white);
  }

  &.bg-light {
    color: var(--#{$prefix}black);
  }
}

.card-plain {
  box-shadow: none;

  .card-footer {
    border-top: none;
  }

  &.no-padding {
    .card-header,
    .card-body,
    .card-footer {
      padding: 0;
    }
  }
}

// Card Decks

// number of cards per line for each breakpoint
$cards-per-line: (
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4
);

// FD: TODO: Card Deck has been dropped. Remove!
// @each $name, $breakpoint in $grid-breakpoints {
//   @media (min-width: $breakpoint) {
//     .card-deck-responsive .card {
//       flex: 0 0 calc(#{math.div(100, map-get($cards-per-line, $name))}% - #{$grid-gutter-width});
//       margin-bottom: 20px;
//     }
//   }
// }


// Card Tabs

.nav.nav-tabs.card-tabs {
  border-bottom: 2px solid transparent;
  position: relative;
  z-index: 1;
  border-radius: 0;
  top: 3px;

  .nav-item {
    cursor: pointer;
    transition: color 0.4s ease, border 0.15s ease, margin 0.2s ease, padding 0.2s ease;
    user-select: none;
    height: 39px;
    border: thin solid transparent;
    text-align: center;
    margin-bottom: -2px;
    display: flex;
    white-space: nowrap;

    &:not(:first-child) {
      // FD: TODO: Collect un-named colors
      border-left: thin solid #c4c4c4;
      border-top-left-radius: 0;
    }

    &:hover, &.active {
    // FD: TODO: Collect un-named colors
      border-left: thin solid #c4c4c4;
      border-right: thin solid #c4c4c4;
      border-top: thin solid #c4c4c4;
      border-bottom-color: transparent;
      background: white;

      a {
        color: var(--#{$prefix}gray-800);
        font-weight: bold;
      }

      & + li {
        border-left-color: transparent;
      }
    }

    .nav-link {
      font-weight: normal;
      // FD: TODO: Collect un-named colors
      color: #bac1cb;
      user-select: none;
      display: inline;
      border: none;
      border-radius: 0;
      text-decoration: none;

      &.bg-primary {
        color: var(--#{$prefix}white);
        background-color: var(--primary-color) !important;
        border-bottom: none;
      }
    }
  }
}
