.copy-value {
  color: transparent;
  position: relative;

  .copy-value-feedback {
    color: var(--#{$prefix}white);
    cursor: default;
    background-color: var(--#{$prefix}gray-500);
    font-size: 0.75rem;
    text-align: center;
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 100%;
    left: 0;
    border-radius: 0.15rem;
    width: 100%;
    height: 100%;
    padding-top: 3px;
  }
}

td > span > .copy-value-feedback {
  padding-top: 0 !important;
  line-height: 15px;
}
