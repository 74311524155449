// START STYLES FROM REDESIGN
.modal {
  .modal-content {
    .modal-header {
      padding: 0;
      margin-bottom: 20px;

      .modal-title {
        font-size: 24px;
        text-transform: none;
        font-weight: normal;
      }

      button.close {
        color: var(--#{$prefix}gray-800);
        font-family: "Times New Roman";
        transition: all 0.3s ease;
        padding: 0;
        margin: 0;
        margin-left: auto;
        user-select: none;
        font-size: 35px;

        &:hover {
          color: var(--#{$prefix}black);
          transform: rotate(90deg);
          transform-origin: center;
        }
      }
    }

    .modal-footer {
      padding: 0;
      margin-top: 20px;

       & > * {
        margin: 0;

       }
    }

    .modal-body {
      padding: 0;
    }
  }
}

.modal-dialog.fullwidth .modal-scroll-container {
  max-height: 68vh;
}

// END STYLES FROM REDESIGN

.modal {
  .modal-content {
    border: none;
    border-radius: inherit;
    padding: 24px;

    .modal-header {
      border: none;
      border-radius: inherit;

      h6 {
        text-transform: uppercase;
      }
    }

    .modal-body {
      border: none;
      border-radius: inherit;

      font-size: 0.8125rem;

      .form-modal-footer {
        margin: 2rem -1rem -1rem -1rem;
      }
    }

    .modal-footer {
      border: none;
      border-radius: inherit;
    }
  }
}

.modal-dialog.fullwidth {
  max-width: 95%;
  max-height: 95vh;

  .modal-body {
    max-height: 85vh;
    padding-bottom: 55px; // button height + white space
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    background: var(--#{$prefix}white);
    padding: 1.625rem;
  }
}

.help-modal[_ngcontent-c0],
.help-modal__container {
  z-index: $zindex-shortcut-help !important;
}

.help-modal__list {
  h4 { font-size: 1rem !important; }
  .description { font-size: .85rem !important; }
  .key { font-size: .85rem !important; }
}

.onboarding-custom {
  margin-bottom: 5rem;

  .modal-body {
    margin-bottom: 4rem;
    max-height: 62vh;
    overflow-y: auto;
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    background: var(--#{$prefix}white);
    padding: 1rem 1.75rem;
  }
}
