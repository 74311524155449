app-assign-user {
  display: block;
  float: left;

  .assign-user-component {
    .user-toggle-container {
      position: relative;
      cursor: pointer;

      &:hover {
        .user-toggle {
          .no-user-assigned {
            border: 1px dashed var(--primary-color);
            fa-icon {
              color: var(--primary-color);
            }
          }
        }

        .add-user {
          background-color: var(--primary-color);
        }
      }

      .user-toggle {
        background-color: var(--#{$prefix}white);
        color: var(--#{$prefix}light) !important;
        font-size: 0.9375rem;
        text-align: center;
        text-decoration: none !important;

        .no-user-assigned {
          width: 2.25rem;
          height: 2.25rem;
          text-align: center;
          position: relative;

          border-radius: 50%;
          border: 1px dashed var(--#{$prefix}light);
          width: 2.25rem;
          height: 2.25rem;
          text-align: center;
          position: relative;

          span {
            font-style: normal;
            vertical-align: middle;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            text-decoration: none;
            width: 2.25rem;
            height: 2.25rem;
            padding-left: 2px;
          }
        }

        &.user-assigned {
          border: 0;
          color: var(--#{$prefix}white) !important;
          padding: 0;
        }
      }

      .remove-user,
      .add-user {
        position: absolute;
        border-radius: 50%;
        background-color: var(--#{$prefix}light);
        cursor: pointer;

        fa-icon {
          color: var(--#{$prefix}white);

          svg {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }

      .remove-user {
        border: 2px solid var(--#{$prefix}white);
        padding: 0.1rem;
        top: -0.4rem;
        right: -0.6rem;
        width: 1.2rem;
        height: 1.2rem;
        visibility: hidden;
      }

      .add-user {
        border: 0;
        padding: 0.1rem;
        bottom: -0.4rem;
        right: -0.1rem;
        width: 1rem;
        height: 1rem;
      }

      .remove-user:hover,
      .user-toggle:hover ~ .remove-user {
        visibility: visible;
      }

      .add-user:hover,
      .remove-user:hover {
        background-color: var(--primary-color);
      }
    }
  }
}

.list-view .assign-user-component .user-toggle {
  font-size: 0.8125rem;
  width: 2rem;
  height: 2rem;

  .no-user-assigned {
    width: 2rem;
    height: 2rem;

    span {
      padding-bottom: 4px;
    }
  }
}

.dropdown-assign-user {
  padding: 0 !important;
  min-width: 200px;

  &.dropup-position {
    margin-top: -35px;
  }

  li.search {
    background-color: var(--#{$prefix}white);
    padding: 0.5rem;

    fa-icon {
      color: var(--#{$prefix}gray-200);
      margin: 0.5rem 0 0 0.5rem;
      position: absolute;
    }
  }

  input.form-control {
    background-color: var(--#{$prefix}white);
    border: 0;
    padding-left: 1.7rem;
    width: 100%;

    &::placeholder {
      color: var(--#{$prefix}gray-200);
      opacity: 1;
    }
  }

  ul.users-list {
    max-height: 300px;
    overflow-y: scroll;
    padding: 0 !important;

    li a.dropdown-item {
      padding: 0.5rem;
      cursor: pointer;
      text-decoration: none;
      font-weight: 400;

      &.active {
        background-color: var(--#{$prefix}gray-200);

        .unassign-user {
          border-color: var(--#{$prefix}white);
          color: var(--#{$prefix}white) !important;
        }
      }

      .user-name {
        line-height: 2.25rem;
        padding-left: 0.5rem;
      }

      .unassign-user {
        border-radius: 50%;
        border: 1px dashed var(--#{$prefix}light);
        color: var(--#{$prefix}light) !important;
      }
    }
  }
}
