$primary-color: var(--primary-color);

app-reclassification {
  .dropdown {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    button.dropdown-item {
      padding: 0 !important;

      .item {
        background-color: transparent;
        padding: .3rem .5rem;

        &.candidate {
          background-color: var(--#{$prefix}gray-100);
          font-weight: bold;
        }
      }

      &.active {
        .item {
          background-color: var(--primary-color);
        }
      }

      .confidence {
        width: 50px;
        text-align: right;
      }
    }
  }
}
