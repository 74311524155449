.left-column,
.right-column {
  height: 100%;
  overflow: hidden;

  .card {
    height: 100%;
    overflow: hidden;
    width: 300px;
  }
}

.column-toggle {
  .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus,
  .btn-light:focus, .btn-light.focus {
    box-shadow: none;
  }

  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
    background-color: var(--#{$prefix}blue);
    border-color: var(--#{$prefix}blue);
  }
}
