@import './typo';

.table-responsive {
  overflow: unset;

  &.table-borderless {
    th,
    td {
      border-bottom-width: 0 !important;
    }
  }

  td {
    padding: 0.5rem;

    div.document-name {
      height: 19.5px;
      display: flex;
      width: 300px;
      position: relative;
      white-space: nowrap;

      span.unhovered-text {
        margin-right: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span.unhovered-text, input {
        max-width: 276px;
      }

      &.document-locked {
        fa-icon:not(.edit) {
          color: var(--#{$prefix}gray-200);
        }
      }

      fa-icon:not(.edit) {
        color: var(--primary-color);
        width: 16px;
      }

      &:not(.edit-mode) {
        input {
          color: var(--primary-color);
          background-color: var(--#{$prefix}white);
        }
      }

      input {
        padding: 0;
        font-size: rem(13px);
        max-height: 19.5px;
        margin-right: 8px;
      }
    }

    & > div.text-clipped {
      max-width: 200px;
      max-height: 40px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.table-responsive:not(.table-borderless) td.text-clipped {
  position: relative;

  & > div {
    width: 100%;
    position: absolute;
    display: flex;
    min-width: 0;
    top: 0;
    height: 100%;
    align-items: center;

    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.table {
  thead {
    &.table-dark {
      th {
        background-color: var(--nav-color);
        border-color: var(--nav-color);
        color: var(--#{$prefix}gray-800);
      }
    }
  }

  &.no-border th,
  &.no-border td {
    border: none;
  }

  &.no-first-border tr:first-child td {
    border: none;
  }

  tr.has-second-row {
    td {
      padding-bottom: 0;
    }
  }

  tr.second-row {
    td {
      border-top: none;
      padding-top: 0;
    }
  }

  tr.top-row {
    td {
      padding-bottom: 0;
    }
  }

  tr.middle-row {
    td {
      border-top: none;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  tr.bottom-row {
    td {
      border-top: none;
      padding-top: 0;
      padding-bottom: 0.75rem;
    }
  }

  tr.dim {
    td:not(.nodim) {
      opacity: 0.3;
    }
  }

  tr.top td,
  td.top,
  th.top {
    vertical-align: top !important;
  }

  &.vertical-center th,
  &.vertical-center td {
    vertical-align: middle;
  }

  th, td {
    vertical-align: middle;
  }

  tr.no-border td {
    border-top: none;
  }

  h3 {
    margin-bottom: 0;
  }
}

table .list-image {
  border: 1px solid var(--#{$prefix}gray-100);
  height: 70px;
  width: auto;
}

.padding-sm {
  td, th {
    padding: .2rem;
  }
}

.padding-md {
  td, th {
    padding: .4rem;
  }
}

.padding-lg {
  td, th {
    padding: .8rem;
  }
}

.padding-y-0 {
  td, th {
    padding: .2rem 0;
  }
}

.padding-y-md {
  td:first-child,
  th:first-child {
    padding-left: .8rem;
  }

  td:last-child,
  th:last-child {
    padding-right: .8rem;
  }
}

.btn-cell {
  padding-bottom: 0.5rem !important;
}


.border-top-2 {
  border-top: 2px solid var(--#{$prefix}gray-300);
}

table.no-wrap {
  td, th {
    white-space: nowrap;
  }
}

table.first-column-xs tr td:first-child,
table.first-column-xs tr th:first-child {
  width: 50px;
  padding-left: 0;
}

table.first-column-sm tr td:first-child,
table.first-column-sm tr th:first-child {
  width: 100px;
  padding-left: 0;
}

table.first-column-md tr td:first-child,
table.first-column-md tr th:first-child {
  width: 150px;
  padding-left: 0;
}

table.first-column-lg tr td:first-child,
table.first-column-lg tr th:first-child {
  width: 200px;
  padding-left: 0;
}

table.first-column-xl tr td:first-child,
table.first-column-xl tr th:first-child {
  width: 250px;
  padding-left: 0;
}

tr.padding-left-10 {
  padding-left: 10px;

  td:first-child {
    padding-left: 10px !important;
  }

  th {
    text-indent: 10px;
  }
}

th.rotate {
  height: 150px;
  white-space: nowrap;
  padding: 0;
}

th.rotate > div {
  transform:
    translate(0, -0.5rem)
    rotate(315deg);
  width: 30px;
}

th.rotate > div > span {
  padding: 0.75rem;
}

tr.added {
  background-color: var(--#{$prefix}gray-100);
}

tr.animate-added {
  -webkit-animation: animate-added-row 2s ease;
  -moz-animation: animate-added-row 2s ease;
  -o-animation: animate-added-row 2s ease;
  animation: animate-added-row 2s ease;
  background-color: var(--#{$prefix}gray-100);
}

@-webkit-keyframes  animate-added-row {
  0% { background-color: var(--#{$prefix}gray-300); }
  100% { background-color: var(--#{$prefix}gray-100); }
}

@-moz-keyframes animate-added-row {
  0% { background-color: var(--#{$prefix}gray-300); }
  100% { background-color: var(--#{$prefix}gray-100); }
}

@-o-keyframes animate-added-row {
  0% { background-color: var(--#{$prefix}gray-300); }
  100% { background-color: var(--#{$prefix}gray-100); }
}

@keyframes animate-added-row {
  0% { background-color: var(--#{$prefix}gray-300); }
  100% { background-color: var(--#{$prefix}gray-100); }
}
