.alert {
  a {
    text-decoration: $link-hover-decoration !important;

    &:hover {
      text-decoration: none !important;
    }

    cursor: pointer;
  }

  &.full-alert {
    background-color: var(--#{$prefix}gray-600);
    border-color: var(--#{$prefix}gray-600);
    border-radius: 0;
    color: var(--#{$prefix}white);
    font-weight: normal;
  }
}
