@mixin tag-variant($bg) {
  color: color-contrast($bg);
  background-color: $bg;

  &[href] {
    &:hover,
    &:focus {
      color: color-contrast($bg);
      text-decoration: none;
      background-color: darken($bg, 10%);
    }
  }
}

.tag {
  color: $white !important;
  cursor: pointer;
  background-color: var(--#{$prefix}gray-600);
  display: inline-block;
  font-size: $tag-font-size;
  font-weight: $tag-font-weight;
  margin-right: 5px;
  padding: $tag-padding-y $tag-padding-x;
  line-height: 1.1;
  text-align: center;
  text-transform: none;
  vertical-align: baseline;
  white-space: nowrap;
  // @include border-radius($badge-border-radius);

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: darken($gray-600, 10%);
  }

  &.dark {
    background-color: var(--#{$prefix}blue);
  }

  &.light {
    background-color: var(--#{$prefix}light);
    color: $gray-900 !important;
  }

  .prefix {
    color: var(--#{$prefix}gray-200);
    margin-right: 0.3rem;
    text-transform: uppercase;
  }

  .times {
    color: var(--#{$prefix}gray-200);
    margin-left: 0.1rem;
    font-weight: 600;
  }
}

@each $color, $value in $theme-colors {
  .tag-#{$color} {
    @include tag-variant($value);
  }
}
