// Latest bootstrap version dropped .close and applied btn-close instead
// We should get rid of it soon
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: $black;
  text-shadow: 0 1px 0 var(--#{$prefix}white);
  opacity: .5;

  &:hover {
    text-decoration: none;
  }
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75 !important;
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

// Future-proof disabling of clicks on `<a>` elements
a.close.disabled {
  pointer-events: none;
}
