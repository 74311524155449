@import './mixins';
@import './variables';
@import './typo';

// Link helper
.pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-default {
  cursor: default !important;
}

// Image helper
.full-img-fluid {
  width: 100%;
  height: auto;
}

// Scroll Container
.table-responsive-new.scroll-horizontal {
  padding-bottom: 0;
  max-height: calc(100vh - 260.1px);

  &.has-page-description {
    max-height: calc(100vh - 295.5px);
  }
  &.has-tabs {
    max-height: calc(100vh - 299px);
    padding-bottom: 0;
  }
}

.scroll-container.fullpage {
  max-height: calc(100vh - 122px);
}

.scroll-container, .scroll-horizontal, .modal-scroll-container {
  overflow-y: auto;
  overflow-x: hidden;

  .card {
    box-shadow: none;
    // FD: TODO: Collect un-named colors
    border: thin solid #c4c4c4;
  }

  @include default-scrollbar;
}

.modal-dialog.fullwidth .modal-scroll-container {
  max-height: 65vh;
  margin-bottom: 20px;

  & + * {
    margin-bottom: 20px;
  }
}

@mixin button-hover-styles {
  &::before {
    content: "";
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    transition: all 0.3s ease;
    border-radius: 2px;
    pointer-events: none;
    background: transparent;
  }

  &:hover {
    &::before {
      // FD: TODO: Collect un-named colors
      background: #fff3;
    }
  }
}

.scroll-horizontal {
  overflow-x: auto;
}

.overflow-hidden {
  overflow: hidden;
}

// Backgrounds
.bg-dark-light {
  // FD: TODO: Collect un-named colors
  background-color: #dce0e2;
}

.bg-light {
  background-color: lighten($gray-100, 1%) !important;
  color: $black;
}


// Opacity

.lighten-80 {
  opacity: 0.2;
}

.lighten-50 {
  opacity: 0.5;
}


// Borders
.border {
  border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color-translucent);
}

.border-top-5 {
  border-top-width: 5px !important;
}

.border-bottom-5 {
  border-bottom-width: 5px !important;
}


// Text helpers
.meta {
  font-size: rem(13px);
}

// Content Column
.content-col {
  padding-bottom: 2rem;
}


// Due date
.overdue {
  color: $red;
}

.on-time {
  color: $green;
}


// Text helper
.u-br { white-space: pre-wrap; }
.u-nowrap { white-space: nowrap; }
.u-wordwrap { word-wrap: break-word; }
.u-whitespace-normal { white-space: normal !important; }
.u-wordwrap-a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
}


// Padding
.ps-2-5 {
  padding-left: 2.5rem !important;
}

.ps-10 {
  padding-left: 10px !important;
}

.pe-10 {
  padding-right: 10px !important;
}

.pe-20 {
  padding-right: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

// Colors
.gray-100 {
  color: var(--#{$prefix}gray-100);
}

.gray-200 {
  color: var(--#{$prefix}gray-200);
}
.gray-300 {
  color: var(--#{$prefix}gray-300);
}

.gray-400 {
  color: var(--#{$prefix}gray-400);
}

.gray-500 {
  color: var(--#{$prefix}gray-500);
}

.gray-600 {
  color: var(--#{$prefix}gray-600);
}

.gray-700 {
  color: var(--#{$prefix}gray-700);
}

.gray-800 {
  color: var(--#{$prefix}gray-800);
}

// Bubble
.bubble {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background: var(--primary-color);
  top: 3px;
  right: -5px;
  transition: opacity .1s ease-in-out;

  &.hide {
    opacity: 0 !important;
  }
}

// Pseudo-Elements
.no-content-before {
  &::before {
    content: none !important;
  }
}

.no-content-after {
  &::after {
    content: none !important;
  }
}

// Fade In

.fade-in {
  animation: fadeIn 1s;
  will-change: opacity;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@function toRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}
