field-dropdown,
ev-field-dropdown,
dd-field-dropdown {
  background: var(--#{$prefix}white);
  display: block;
  position: absolute;
  top: 33px;
  left: 0;
  min-width: 100% !important;
  width: 100%;
  z-index: 1045;

  .result-container {
    box-shadow: 0px 0px 3px var(--#{$prefix}gray-200);
    font-size: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.15rem;

    ul {
      padding: 0;
      margin: 0;
      overflow-y: auto;
      max-height: 200px;
      position: relative;

      li {
        cursor: pointer;
        padding: .3rem .5rem;

        &.candidate {
          background: var(--#{$prefix}gray-100);
        }

        &.active {
          color: white;
          text-decoration: none;
          background: var(--primary-color);
        }
      }
    }
  }

  table-input field-dropdown {
    z-index: 1090;
  }
}
