// Base font size = 16px (= 1rem)
//
// px <=> rem calculations:
// 8px = 0.5rem
// 9px = 0.5625rem
// 10px = 0.625rem
// 11px = 0.6875rem
// 12px = 0.75rem
// 13px = 0.8125rem
// 14px = 0.875rem
// 15px = 0.9375rem
// 16px = 1rem (base)
// 18px = 1.125rem
// 20px = 1.25rem
// 22px = 1.375rem
// 24px = 1.5rem
// 26px = 1.625rem
// 28px = 1.75rem
// 30px = 1.875rem
// 32px = 2rem
// 34px = 2.125rem
// 36px = 2.25rem
// 38px = 2.375rem
// 40px = 2.5rem

@use 'sass:math';

@function rem($pxValue) {
  $html-font-size: 16px;

  @return #{math.div(strip-units($pxValue), strip-units($html-font-size))}rem;
}

@function strip-units($number) {
  @return math.div($number, $number * 0 + 1);
}

a {
  text-decoration: none;
  background-color: transparent;
}

b,
strong {
  font-family: 'Barlow bold', 'Calibri', sans-serif;
}

h3 {
  font-size: 2rem;
  font-weight: 600;
}

h6 {
  font-weight: bold;
  margin-bottom: $spacer;
}

a {
  &:hover {
    text-decoration: underline;
  }

  &.unmarked {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

a.text-link {
  color: var(--#{$prefix}blue);

  &:hover {
    color: var(--#{$prefix}blue);
  }

  &.unmarked {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

a.plain {
  text-decoration: none !important;
}

.hover:hover {
  color: var(--#{$prefix}blue);
}

.default-content {
  font-size: $font-size-content;
  text-transform: none;
  font-weight: 500;
}

.font-size-base {
  font-size: 1rem;
}

.bold {
  font-weight: bold;
}
