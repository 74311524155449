.grid-card {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: min-content 1fr min-content;
  grid-gap: 0;
  grid-template-areas:
    "grid-card-header"
    "grid-card-body"
    "grid-card-footer";

  border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color-translucent);
  background-color: var(--#{$prefix}body-bg);
  background-clip: border-box;
  @include border-radius(var(--#{$prefix}border-radius));
  color: var(--#{$prefix}body-color);

  .grid-card-header {
    grid-area: grid-card-header;
    padding: 1rem;
    overflow-y: auto;
  }

  .grid-card-body {
    grid-area: grid-card-body;
    padding: 0 1rem 0 1rem;
    overflow: hidden;

    .table-responsive-new.scroll-horizontal {
      padding: 0;
      max-height: 100% !important;
      height: 100% !important;
    }
  }

  .grid-card-footer {
    grid-area: grid-card-footer;
    padding: 0 1rem 1rem 1rem;
    overflow-y: auto;
  }
}
