@import './typo';

@keyframes moving-gradient {
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
}

.table-responsive-new {
  overflow: unset;

  .hide-table-header {
    visibility: hidden;

    tr th > div {
      height: 0 !important;
      margin-top: -46px;
    }
  }

  list-bulk-action {
    display: none;
    position: sticky;
    top: 0;
    z-index: 12;
    height: 46px;
    padding: 6px 8px 8px 12px;
    background: var(--#{$prefix}gray-100);
    border-bottom: 2px solid var(--#{$prefix}gray-200);

    list-bulk-action-checkbox-toggle {
      width: 23px;
    }

    .list-bulk-action-container {
      display: flex;
      align-items: center;
    }

    &.is-bulk-edit {
      display: table-caption;
    }

    span.selected-counter {
      font-size: 10px;
      padding: 0 12px 0 8px;
      width: 75px;
    }

    // Button dropdown
    .btn-group {
      margin-left: 8px !important;

      button {
        margin-left: 0;
      }

      &.open {
        button {
          background-color: $primary-color !important;
          color: $white !important;
          box-shadow: inset 0 0 0 2px $primary-color, rgba(0, 0, 0, 0.12) 0 4px 5px 0;

          fa-icon {
            color: $white;
          }

          &::after {
            background: $primary-color;
          }
        }
      }

      .dropdown-menu {
        background-color: $primary-color !important;
        border: 1px solid $primary-color !important;
        border-radius: 2px;
        color: $white;
        li a {
          color: $white;
          font-weight: 400;
          text-decoration: none;
          cursor: pointer;

          &:hover {
            background: $white !important;
            color: $primary-color !important;
          }
        }
      }
    }

    button {
      color: var(--primary-color) !important;
      font-size: 12px;
      background: none !important;
      margin-left: 8px;

      span {
        pointer-events: all;
      }

      &.button-secondary:hover {
        background-color: transparent;
        box-shadow: inset 0 0 0 2px var(--primary-color), rgba(0, 0, 0, 0.12) 0 4px 5px 0;

        fa-icon {
          color: var(--#{$prefix}white);
        }

        &::after {
          background: var(--primary-color);
        }
      }

      &.button-secondary[disabled], &.button-secondary:disabled, &.button-secondary.disabled {
        background: var(--#{$prefix}gray-200) !important;
        color: var(--#{$prefix}white) !important;

        &:hover {
          box-shadow: none;
        }
      }

      &.delete, &.delete fa-icon {
        color: var(--#{$prefix}red) !important;

        &:hover {
          box-shadow: inset 0 0 0 2px var(--#{$prefix}red), rgb(0 0 0 / 12%) 0 4px 5px 0;

          fa-icon {
            color: var(--#{$prefix}white) !important;
          }

          &::after {
            background: var(--#{$prefix}red);
          }
        }
      }

      &:hover {
        color: var(--#{$prefix}white) !important;

        &::after {
          z-index: -1 !important;
        }
      }

      fa-icon {
        margin-right: 4px;
      }
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none;
    }

    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: var(--#{$prefix}gray-300);
    }

    .custom-control-input:indeterminate ~ .custom-control-label::before {
      border-color: var(--primary-color) !important;
      background-color: var(--primary-color) !important;
    }
  }

  td {
    padding: 0.5rem;

    div.document-name {
      height: 20px;
      display: flex;
      top: 8px;
      position: absolute;
      white-space: nowrap;

      span.unhovered-text {
        margin-right: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span.unhovered-text, input {
        max-width: 276px;
      }

      &.document-locked {
        fa-icon:not(.edit) {
          color: var(--#{$prefix}gray-200);
        }
      }

      fa-icon:not(.edit) {
        color: var(--primary-color);
        width: 11px;
      }

      &:not(.edit-mode) {
        input {
          color: var(--primary-color);
          background-color: var(--#{$prefix}white);
        }
      }

      input {
        padding: 0;
        font-size: rem(10px);
        max-height: 19.5px;
        margin-right: 8px;
        margin-top: -2px;
      }
    }

    & > div.text-clipped {
      max-width: 200px;
      max-height: 40px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.table-responsive-new {
  overflow: unset;
  position: relative;

  table {
    margin: 0;
    width: 100%;

    th {
      position: sticky;
      top: 0;
      z-index: 2;
      padding: 0;
      font-size: rem(12px);
      border: none;

      &.thumbnail {
        min-width: 108px;
        width: 141px;
        max-width: 141px;
      }

      &:first-child > div {
        padding-left: 12px;
      }

      & > div {
        border-bottom: 2px solid var(--#{$prefix}gray-200);
        padding: 12px 8px;
        display: flex;
        white-space: nowrap;
        height: 46px;
        align-items: center;

        &.centered {
          justify-content: center;
        }

        &.text-end {
          justify-content: flex-end;
        }

        span span {
          font-weight: 100;
        }

        fa-icon + span {
          margin-left: 4px;
        }
      }
    }

    tbody tr {
      &:not(:last-child) {
        box-shadow: 0 1px 0px -0.1px var(--#{$prefix}gray-200); // basically equal to border-bottom: thin solid w/o affecting element dimensions
      }

      &:hover {
        // FD: TODO: Collect un-named colors
        box-shadow: 0 1px 0px -0.1px var(--#{$prefix}gray-200), 0 3px 24px 0 #c2cfd699;
        position: relative;
        cursor: pointer;
        z-index: 1;

        td.bulk input[type=checkbox] + label {
          display: block;
        }
      }

      td div {
        fa-icon + span {
          margin-left: 2px;
        }
      }
    }

    td {
      border: none;
      padding: 0.5rem;

      &:first-child {
        padding-left: 12px;
      }

      span.title {
        color: var(--primary-color);
        font-weight: bold;
      }

      span.unclassified {
        color: var(--#{$prefix}gray-200);
      }

      &.thumbnail {
        min-width: 108px;
        width: 141px;
        max-width: 141px;

        .title {
          margin-left: 8px;
          max-width: 300px;
          text-overflow: ellipsis;
        }
      }

      span.doc-status {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        background: var(--#{$prefix}gray-400);

        &.done {
          background: var(--#{$prefix}green);
        }

        &.failed {
          background: var(--#{$prefix}red);
        }

        &.separation_validation.step_in_progress,
        &.separation_validation.step_retry,
        &.separation_qc.step_in_progress,
        &.separation_qc.step_retry,
        &.classification_validation.step_in_progress,
        &.classification_validation.step_retry,
        &.classification_qc.step_in_progress,
        &.classification_qc.step_retry,
        &.extraction_validation.step_in_progress,
        &.extraction_validation.step_retry,
        &.extraction_qc.step_in_progress,
        &.extraction_qc.step_retry {
            background: var(--#{$prefix}yellow);
        }
      }

      div.field_errors--value {
        max-width: 188px;
      }

      div.key_to_copy {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 38.7px;

        span:not(.btn_copy) {
          color: var(--#{$prefix}gray-200);
          margin-right: 12px;
          width: 150px;
        }

        span.btn_copy {
          padding: 4px;
          border: thin solid var(--#{$prefix}gray-200);
          border-radius: 4px;
          background: var(--#{$prefix}gray-100);
          color: var(--#{$prefix}gray-800);
          display: flex;
        }
      }

      & > div.text-clipped {
        max-width: 200px;
        max-height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.expert-status {
        padding: .3rem .5rem .3rem 0;
      }

      &.expert-tenant {
        padding-left: 0;

        span {
          margin-left: -5px;
        }
      }
    }

    th.bulk > div {
      padding-right: 0;
    }

    th.bulk,
    td.bulk {
      width: 35px;
      padding-right: 0;

      .form-check-input {
        background-color: var(--#{$prefix}gray-100);
        border: 2px solid var(--#{$prefix}gray-200);

        &:checked {
          background-color: var(--primary-color);
          border-color: var(--primary-color);
        }
      }
    }

    tr.group-header {
      // FD: TODO: Collect un-named colors
      background-color: #dbe0e2;
      box-shadow: none !important;

      &:hover {
        box-shadow: none !important;
      }

      td {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
      }

      td.bulk {
        width: 50px;

        .form-check {
          margin-right: -4px;
          margin-top: 2px;

          .form-check-input {
            &[type="checkbox"]:indeterminate,
            &:checked {
              background-color: var(--primary-color);
              border-color: var(--primary-color);
            }
          }
        }

        fa-icon {
          font-size: 1.2rem;
          margin-right: 0.4rem;
          margin-top: 2px;
        }
      }

      td:not(.bulk) {
        font-weight: bold;
      }
    }

    tr.grouped {
      td.bulk {
        width: 50px;

        .form-check {
          margin-right: 0;
          padding-left: 47px;
        }

        fa-icon {
          font-size: 1.2rem;
          margin-right: 0.4rem;
        }
      }
    }
  }

  table.loading {
    tbody tr td {
      &.thumbnail {
        vertical-align: middle;

        div {
          display: block !important;
        }
      }

      &.loading-item {
        div {
          display: inline-block;
          background: linear-gradient(to right, rgba(240, 243, 245, 0.59) 20%, rgba(240, 243, 245, 0.1) 60%, rgba(240, 243, 245, 0.59) 80%);
          background-size: 500px 100px;
          animation-name: moving-gradient;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-fill-mode: forwards;

          &.lg {
            width: 150px;
            min-width: 150px;
            height: 12px;
            min-height: 12px;
          }

          &.md {
            width: 93px;
            min-width: 93px;
            height: 12px;
            min-height: 12px;
          }

          &.sm {
            width: 15px;
            min-width: 15px;
            height: 12px;
            min-height: 12px;
          }

          &.id {
            width: 70px;
            min-width: 70px;
            height: 12px;
            min-height: 12px;
          }

          &.image {
            width: 92px;
            min-width: 92px;
            height: 37px;
            min-height: 37px;
          }

          &.button-md {
            width: 114px;
            min-width: 114px;
            height: 33px;
            min-height: 33px;
            margin-right: 0.25rem !important;
          }

          &.button-sm {
            width: 13px;
            min-width: 13px;
            height: 33px;
            min-height: 33px;
            margin-right: 0.25rem !important;
          }
        }
      }

      span.doc-status {
        background: rgba(var(--#{$prefix}gray-100), 1);
      }
    }
  }
}

.table-new {
  caption-side: top;

  &.meta.meta.meta { // allows override of th vs .table-responsive table th
    font-size: rem(11px);

    span.badge {
      margin-bottom: 2px;
    }

    th {
      font-weight: bold;
      padding-left: 8px;
    }
  }
  thead {
    &.table-dark {
      th {
        border-top: none;
        text-transform: uppercase;
        background-color: var(--#{$prefix}gray-100);
        color: var(--#{$prefix}gray-800);
      }
    }
  }

  &.no-border th,
  &.no-border td {
    border: none;
  }

  &.no-first-border tr:first-child td {
    border: none;
  }

  tr.has-second-row {
    td {
      padding-bottom: 0;
    }
  }

  tr.second-row {
    td {
      border-top: none;
      padding-top: 0;
    }
  }

  tr.top-row {
    td {
      padding-bottom: 0;
    }
  }

  tr.middle-row {
    td {
      border-top: none;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  tr.bottom-row {
    td {
      border-top: none;
      padding-top: 0;
      padding-bottom: 0.75rem;
    }
  }

  tr.dim {
    td:not(.nodim) {
      opacity: 0.3;
    }
  }

  tr.top td,
  td.top,
  th.top {
    vertical-align: top !important;
  }

  &.vertical-center th,
  &.vertical-center td {
    vertical-align: middle;
  }

  th, td {
    vertical-align: middle;
  }

  tr.no-border td {
    border-top: none;
  }

  h3 {
    margin-bottom: 0;
  }
}

table .list-image {
  border: 1px solid var(--#{$prefix}gray-100);
  height: auto;
}

.padding-sm {
  td, th {
    padding: .2rem;
  }
}

.padding-md {
  td, th {
    padding: .4rem;
  }
}

.padding-lg {
  td, th {
    padding: .8rem;
  }
}

.padding-y-0 {
  td, th {
    padding: .2rem 0;
  }
}

.padding-y-md {
  td:first-child,
  th:first-child {
    padding-left: .8rem;
  }

  td:last-child,
  th:last-child {
    padding-right: .8rem;
  }
}

.btn-cell {
  padding-bottom: 0.5rem !important;
}


.border-top-2 {
  border-top: 2px solid var(--#{$prefix}gray-300);
}

table.no-wrap {
  td, th {
    white-space: nowrap;
  }
}

table.first-column-xs tr td:first-child,
table.first-column-xs tr th:first-child {
  width: 50px;
  padding-left: 0;
}

table.first-column-sm tr td:first-child,
table.first-column-sm tr th:first-child {
  width: 100px;
  padding-left: 0;
}

table.first-column-md tr td:first-child,
table.first-column-md tr th:first-child {
  width: 150px;
  padding-left: 0;
}

table.first-column-lg tr td:first-child,
table.first-column-lg tr th:first-child {
  width: 200px;
  padding-left: 0;
}

table.first-column-xl tr td:first-child,
table.first-column-xl tr th:first-child {
  width: 250px;
  padding-left: 0;
}

tr.padding-left-10 {
  padding-left: 10px;

  td:first-child {
    padding-left: 10px !important;
  }

  th {
    text-indent: 10px;
  }
}

th.rotate {
  height: 150px;
  white-space: nowrap;
  padding: 0;
}

th.rotate > div {
  transform:
    translate(0, -0.5rem)
    rotate(315deg);
  width: 30px;
}

th.rotate > div > span {
  padding: 0.75rem;
}

tr.added {
  background-color: var(--#{$prefix}gray-100);
}

tr.animate-added {
  -webkit-animation: animate-added-row 2s ease;
  -moz-animation: animate-added-row 2s ease;
  -o-animation: animate-added-row 2s ease;
  animation: animate-added-row 2s ease;
  background-color: var(--#{$prefix}gray-100);
}

@-webkit-keyframes  animate-added-row {
  0% { background-color: var(--#{$prefix}gray-300); }
  100% { background-color: var(--#{$prefix}gray-100); }
}

@-moz-keyframes animate-added-row {
  0% { background-color: var(--#{$prefix}gray-300); }
  100% { background-color: var(--#{$prefix}gray-100); }
}

@-o-keyframes animate-added-row {
  0% { background-color: var(--#{$prefix}gray-300); }
  100% { background-color: var(--#{$prefix}gray-100); }
}

@keyframes animate-added-row {
  0% { background-color: var(--#{$prefix}gray-300); }
  100% { background-color: var(--#{$prefix}gray-100); }
}
