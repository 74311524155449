list-data-with-tooltip {
  .text-clipped > span {
    text-overflow: ellipsis;
    max-width: 300px;
    white-space: nowrap;
    display: inline-block;
    margin-top: 3px;
    overflow: hidden;
  }
}
