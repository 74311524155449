$status-colors: (
  'active':                                   var(--#{$prefix}green),
  'archived':                                 var(--#{$prefix}gray-400),
  'beta':                                     var(--#{$prefix}yellow),
  'classification_done':                      var(--#{$prefix}green),
  'classification_in_progress':               var(--#{$prefix}yellow),
  'classification_pending':                   var(--#{$prefix}yellow),
  'completed':                                var(--#{$prefix}green),
  'deactivated':                              var(--#{$prefix}gray-400),
  'deletion_in_progress':                     var(--#{$prefix}red),
  'deletion-in-progress':                     var(--#{$prefix}red),
  'dev':                                      var(--#{$prefix}yellow),
  'done':                                     var(--#{$prefix}green),
  'downloading':                              var(--#{$prefix}yellow),
  'draft':                                    var(--#{$prefix}gray-400),
  'extraction_done':                          var(--#{$prefix}green),
  'extraction_in_progress':                   var(--#{$prefix}yellow),
  'failed':                                   var(--#{$prefix}red),
  'secondary':                                var(--#{$prefix}secondary),
  'manual_classification_in_progress':        var(--#{$prefix}yellow),
  'manual_classification_required':           var(--#{$prefix}yellow),
  'manual_extraction_in_progress':            var(--#{$prefix}yellow),
  'manual_extraction_required':               var(--#{$prefix}yellow),
  'manual_qa_in_progress':                    var(--#{$prefix}yellow),
  'manual_qa_required':                       var(--#{$prefix}yellow),
  'merge_in_progress':                        var(--#{$prefix}yellow),
  'paid':                                     var(--#{$prefix}green),
  'paused':                                   var(--#{$prefix}red),
  'red':                                      var(--#{$prefix}red),
  'pending':                                  var(--#{$prefix}gray-400),
  'in_progress':                              var(--#{$prefix}yellow),
  'internal':                                 var(--#{$prefix}red),
  'preview_in_progress':                      var(--#{$prefix}yellow),
  'processing':                               var(--#{$prefix}yellow),
  'processing_done':                          var(--#{$prefix}yellow),
  'production':                               var(--#{$prefix}green),
  'public':                                   var(--#{$prefix}green),
  'retry':                                    var(--#{$prefix}gray-400),
  'review':                                   var(--#{$prefix}red),
  'skipped':                                  var(--#{$prefix}green),
  'stable':                                   var(--#{$prefix}green),
  'started':                                  var(--#{$prefix}gray-400),
  'stopped':                                  var(--#{$prefix}gray-400),
  'stored':                                   var(--#{$prefix}yellow),
  'test':                                     var(--#{$prefix}yellow),
  'upload_failed':                            var(--#{$prefix}red),
  'waiting':                                  var(--#{$prefix}yellow),
  'yellow':                                   var(--#{$prefix}yellow)
);

@mixin badge-variant($bg) {
  // color: $bg;
  background-color: $bg;
  border: 1px solid $bg;

  &[href] {
    &:hover,
    &:focus {
      background-color: $bg;
    }
  }
}

.badge {
  padding: 4px 6px;
  font-size: 75% !important;
}

@each $color, $value in $status-colors {
  .badge.#{$color} {
    @include badge-variant($value);
    color: var(--#{$prefix}white);
  }
}

.badge-item {
  display: inline-block;
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  // FD: TODO: Collect un-named colors
  background-color: #dbdcdc;
  display: inline-block;
  border-radius: 10rem;
  padding: 4px 6px;
}
