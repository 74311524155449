@import './typo';

bs-tooltip-container {
  z-index: $zindex-tooltip;
}

.tooltip-top {
  top: -6px !important;
}

.tooltip-bottom {
  top: 6px !important;
}

.tooltip-arrow {
  position: absolute;
}

.tooltip-inner {
  background-color: var(--nav-color);
  color: var(--sidebar-bg);
  font-size: rem(12px);
  white-space: pre-line;
  max-width: unset;
  text-align: left;
  font-family: 'Barlow', 'Calibri', sans-serif;
  max-width: unset;

  strong {
    font-size: rem(12px);
  }

  span {
    white-space: nowrap;
  }
}

.wide .tooltip-inner {
  text-align: left !important;
}

.tooltip.top .tooltip-arrow:before,
.tooltip.top .tooltip-arrow {
  border-top-color: var(--nav-color);
}

.tooltip.right .tooltip-arrow:before,
.tooltip.right .tooltip-arrow {
  border-right-color: var(--nav-color);
}

.tooltip.bottom .tooltip-arrow:before,
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: var(--nav-color);
}

.success {
  .tooltip-inner {
    background-color: var(--#{$prefix}green);
    color: var(--#{$prefix}white);
  }

  &.tooltip.top .tooltip-arrow:before,
  &.tooltip.top .tooltip-arrow {
    border-top-color: var(--#{$prefix}green);
  }
}

.warning {
  .tooltip-inner {
    background-color: var(--#{$prefix}yellow);
    color: var(--#{$prefix}white);
  }

  &.tooltip.top .tooltip-arrow:before,
  &.tooltip.top .tooltip-arrow {
    border-top-color: var(--#{$prefix}yellow);
  }
}

.danger {
  .tooltip-inner {
    background-color: var(--#{$prefix}red);
    color: var(--#{$prefix}white);
  }

  &.tooltip.top .tooltip-arrow:before,
  &.tooltip.top .tooltip-arrow {
    border-top-color: var(--#{$prefix}red);
  }
}
