/* Fonts */
@import '../assets/fonts/Barlow/stylesheet';

/* Bootstrap */
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import './../../../../node_modules/bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import 'bootstrap-variables';
// @import 'variables';

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import './../../../../node_modules/bootstrap/scss/variables';
@import './../../../../node_modules/bootstrap/scss/variables-dark';

// 4. Include any default map overrides here
@import 'parashift/color-maps';

// 5. Include remainder of required parts
@import './../../../../node_modules/bootstrap/scss/maps';
@import './../../../../node_modules/bootstrap/scss/mixins';
@import './../../../../node_modules/bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import './../../../../node_modules/bootstrap/scss/utilities';
@import './../../../../node_modules/bootstrap/scss/reboot';
@import './../../../../node_modules/bootstrap/scss/type';
@import './../../../../node_modules/bootstrap/scss/images';
@import './../../../../node_modules/bootstrap/scss/containers';
@import './../../../../node_modules/bootstrap/scss/grid';
@import './../../../../node_modules/bootstrap/scss/helpers';
@import './../../../../node_modules/bootstrap/scss/tables';
@import './../../../../node_modules/bootstrap/scss/forms';
@import './../../../../node_modules/bootstrap/scss/buttons';
@import './../../../../node_modules/bootstrap/scss/transitions';
@import './../../../../node_modules/bootstrap/scss/dropdown';
@import './../../../../node_modules/bootstrap/scss/button-group';
@import './../../../../node_modules/bootstrap/scss/nav';
@import './../../../../node_modules/bootstrap/scss/navbar';
@import './../../../../node_modules/bootstrap/scss/card';
@import './../../../../node_modules/bootstrap/scss/breadcrumb';
@import './../../../../node_modules/bootstrap/scss/pagination';
@import './../../../../node_modules/bootstrap/scss/badge';
@import './../../../../node_modules/bootstrap/scss/alert';
@import './../../../../node_modules/bootstrap/scss/progress';
@import './../../../../node_modules/bootstrap/scss/list-group';
@import './../../../../node_modules/bootstrap/scss/close';
@import './../../../../node_modules/bootstrap/scss/toasts';
@import './../../../../node_modules/bootstrap/scss/modal';
@import './../../../../node_modules/bootstrap/scss/tooltip';
@import './../../../../node_modules/bootstrap/scss/spinners';
@import './../../../../node_modules/bootstrap/scss/offcanvas';
@import './../../../../node_modules/bootstrap/scss/placeholders';
@import './../../../../node_modules/bootstrap/scss/accordion';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import './../../../../node_modules/bootstrap/scss/utilities/api';

/* Third Party CSS */
@import './../../../../node_modules/ngx-toastr/toastr-bs5-alert';

/* Custom CSS */
@import 'body';
@import 'animate';
@import 'mixins';
@import 'alert';
@import 'assign-user';
@import 'badge';
@import 'breadcrumb';
@import 'buttons';
@import 'cards';
@import 'close';
@import 'columns';
@import 'copy-value';
@import 'custom-forms';
@import 'datepicker';
@import 'document-thumbnail';
@import 'dropdowns';
@import 'empty-result-description-component';
@import 'fa-icons';
@import 'field-dropdown';
@import 'drop-and-upload-file-component';
@import 'filter-column';
@import 'filter-sorting';
@import 'input-group';
@import 'forms';
@import 'grid-card';
@import 'layout-full-page';
@import 'layout-simple-page';
@import 'lists';
@import 'list-data-with-tooltips';
@import 'modals';
@import 'pagination';
@import 'progress-bar';
@import 'reclassification-dropdown';
@import 'tables';
@import 'tables-new';
@import 'tags';
@import 'toastr';
@import 'tooltip';
@import 'typo';
@import 'user-avatar';
@import 'utilities';

/* Codemirror base CSS and theme file */
@import './../../../../node_modules/codemirror/lib/codemirror';
@import './../../../../node_modules/codemirror/theme/material';
@import './../../../../node_modules/codemirror/theme/idea';
// @import './../../../../node_modules/codemirror/theme/default';
