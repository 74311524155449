app-drop-and-upload-file {
  .file-drop-area {
    padding: 10px 25px;
    margin-bottom: 25px;
    // FD: TODO: Collect un-named colors
    border: 1px dashed #c3ccd2;
    border-radius: 5px;
    width: 100%;
    overflow: hidden;

    &--file-over {
      border: 1px dashed var(--#{$prefix}gray-700);
      background-color: var(--#{$prefix}gray-100);
    }

    &__icon {
      fa-icon {
        font-size: 50px;
        float: left;
        margin: 0px 20px 0px 0px;
      }
    }

    &__hint-text {
      h3 {
        font-size: 1.2rem;
      }
      p {
        font-size: 0.8rem;
        font-weight: normal;
      }
    }
  }
}
